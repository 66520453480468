import Header from "./Header";
import Footer from "./Footer";
import foodAppVideo from "../assets/foodApp.mp4";
import foodAppScreenshot1 from "../assets/foodApp1.png";
import foodAppScreenshot2 from "../assets/foodApp2.png";
import foodAppScreenshot3 from "../assets/foodApp3.png";

const Food = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-1/2 my-8 mx-auto montserrat">
        <h1 className="text-[64px] leading-[80px] mb-11">
          Aplicación de comida a domicilio
        </h1>

        <a
          href="http://foodapp.ballesterosrivasa.com/"
          className="uppercase bg-black hover:bg-[#43464d] text-white text-[1vw] py-3 px-5 w-fit text-center"
          target="_blank"
          rel="noreferrer"
        >
          Enlace a la aplicación
        </a>
        <h2 className="text-[20px] leading-[34px] mt-11 mb-3.5">
          Descripción del proyecto
        </h2>
        <p className="text-[#8f8f91] text-[16px] leading-[28px]">
          Es una aplicación responsiva con la que pedir comida a domicilio. Para
          construirla he utilizado Node.js, React y Tailwind CSS para el
          frontend, Express para el backend y Axios para la conexión con la API. 
          Con Express he creado una API con los datos de los productos que muestra 
          el frontend. También he usado React Router para navegar entre las 
          distintas rutas de la aplicación.
        </p>
      </div>

      <div className="flex flex-col w-full items-center mt-28">
        <img src={foodAppScreenshot1} alt="" className="mb-14" />
        <img src={foodAppScreenshot2} alt="" className="mb-14" />
        <img src={foodAppScreenshot3} alt="" className="mb-14" />
        <video
          // width="1080"
          src={foodAppVideo}
          loop
          autoplay="true"
          muted
          className="mb-14"
        />
      </div>
      <Footer />
    </>
  );
};

export default Food;
