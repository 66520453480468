import Formulario from "./Formulario";
import Header from "./Header";
import Footer from "./Footer";

const Contacto = () => {
  return (
    <div className="flex flex-col h-[100vh]">
      <Header currentLocation={"Contacto"} />
      <Formulario />
      <Footer />
    </div>
  );
};

export default Contacto;
