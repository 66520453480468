import Header from "./Header";
import Footer from "./Footer";
import Projects from "./Projects";
import useBreakpoints from "./useBreakPoints";

const Home = () => {
  const { isXs, isSm, isMd } = useBreakpoints();

  let fontSize = null;
  if (isMd) {
    fontSize = "mdFontSize"
  }
  else {
    fontSize = "text-[64px] leading-[80px]"
  }
  return (
    <>
      <Header currentLocation={"Proyectos"} />
      <div className="justify-center my-32 mx-[30px]">
        <section className={`mx-auto montserrat w-full max-w-[1140px] ${isXs || isSm ? "text-center" : ""} `}>
          <h1 className="text-xl mb-[5px]">Alberto Ballesteros Rivas</h1>
          <h2 className="text-[#bcbcbd] mb-2.5">Desarrollador web</h2>
          <p className={`${fontSize}`}>Hola, soy un desarrollador web de Motril, Granada.</p>
        </section>
      </div>
      <Projects />
      <Footer />
    </>
  );
};

export default Home;
