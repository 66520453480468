import Header from "./Header";
import Footer from "./Footer";
import jobsAppScreenshot1 from "../assets/jobApp1.png"
import jobsAppScreenshot2 from "../assets/jobApp2.png"
import jobsAppScreenshot3 from "../assets/jobApp3.png"
import jobsAppVideo from "../assets/jobsAppVideo.mp4"

const Social = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-1/2 my-8 mx-auto montserrat">
        <h1 className="text-[64px] leading-[80px] mb-11">
          Portal de empleo
        </h1>

        <a
          href="https://jobsapp.ballesterosrivasa.com/"
          className="uppercase bg-black hover:bg-[#43464d] text-white text-[1vw] py-3 px-5 w-fit text-center"
          target="_blank"
          rel="noreferrer"
        >
          Enlace a la aplicación
        </a>
        <h2 className="text-[20px] leading-[34px] mt-11 mb-3.5">
          Descripción del proyecto
        </h2>
        <p className="text-[#8f8f91] text-[16px] leading-[28px]">
          Es una aplicación responsiva con la que ofrecer y encontrar trabajo. Para
          construirla he utilizado Laravel para el backend, HTML5, Tailwind CSS y JavaScript para 
          el frontend, y MySQL como base de datos. 
          La app consta de gestión de usuarios, que son los que crean, editan y/o eliminan las ofertas 
          de trabajo, un filtro de las ofertas por sus etiquetas y una búsqueda de palabras clave.
        </p>
      </div>

      <div className="flex flex-col w-full items-center mt-28">
        <img src={jobsAppScreenshot1} alt="" className="mb-14" />
        <img src={jobsAppScreenshot2} alt="" className="mb-14" />
        <img src={jobsAppScreenshot3} alt="" className="mb-14" />
        <video
          // width="1080"
          src={jobsAppVideo}
          loop
          autoplay="true"
          muted
          className="mb-14"
        />
      </div>
      <Footer />
    </>
  );
};

export default Social;
