import Header from "./Header";
import Footer from "./Footer";
import htmlIcon from "../assets/html.png";
import cssIcon from "../assets/css.png";
import jsIcon from "../assets/js.png";
import nodeIcon from "../assets/node.png";
import reactIcon from "../assets/react.png";
import mongoDBIcon from "../assets/mongoDB.png";
import phpIcon from "../assets/php.png";
import sqlIcon from "../assets/sql.png";
import gitIcon from "../assets/git.png";

const Sobre = () => {
  return (
    <div className="flex flex-col h-[100vh]">
      <Header currentLocation={"Sobre mí"} />
      <div className="grow-[2] flex flex-col items-center mt-10">
        <div className="montserrat w-1/2">
          <h1 className="text-[36px] leading-[50px] mb-4">Sobre mí</h1>
          <spam className="text-[16px] leading-[28px] text-[#8f8f91]">
            Soy Alberto Ballesteros Rivas. Me gusta construir aplicaciones web,
            disfruto con el frontend y con el backend. Aprendo y desarrollo
            nuevas habilidades continuamente para convertirme en un mejor
            desarrollador.
            <br />
            Soy técnico superior en desarrollo de aplicaciones web. Durante el
            ciclo aprendí los lenguajes HTML, CSS, PHP, JavaScript y SQL. Al
            terminarlo descubrí el stack MERN (MongoDB, Express, React y
            Nodejs), me encantó su funcionamiento y empecé a estudiarlo en
            cursos como Full Stack open (
            <a
              href="https://fullstackopen.com/en/"
              target="_blank"
              rel="noreferrer"
            >
              https://fullstackopen.com/en/
            </a>
            ) y The Odin Project (
            <a
              href="https://www.theodinproject.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.theodinproject.com/
            </a>
            ).
          </spam>
        </div>

        <div className="montserrat mt-20 w-1/2">
          <h2 className="text-[30px] leading-[46px]">Mis habilidades</h2>
          <ul className="flex mt-8 items-end flex-wrap">
            <li className="flex flex-col items-center justify-center mr-5 my-2">
              <img src={htmlIcon} alt="" />
              <span>HTML5</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={cssIcon} alt="" className="max-w-[50px]" />
              <span>CSS3</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={jsIcon} alt="" />
              <span>Javascript</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={reactIcon} alt="" />
              <span>React</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={nodeIcon} alt="" />
              <span>Node.js</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={phpIcon} alt="" className="max-w-[60px]" />
              <span>PHP</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={mongoDBIcon} alt="" className="max-w-[50px]" />
              <span>MongoDB</span>
            </li>
            <li className="flex flex-col items-center justify-center mx-5 my-2">
              <img src={sqlIcon} alt="" className="max-w-[40px]" />
              <span>SQL</span>
            </li>
            <li className="flex flex-col items-center justify-center ml-5 my-2">
              <img src={gitIcon} alt="" className="max-w-[50px]" />
              <span>GIT</span>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sobre;
