import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import useBreakpoints from "./useBreakPoints";

const Formulario = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { isXs, isSm, isMd } = useBreakpoints();

  const handleSubmit = (event) => {
    event.preventDefault();
    //const data = { name: name, email: email, message: message };
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: { name: name, email: email, message: message },
    };
    axios
      .post(
        "https://public.herotofu.com/v1/846bc7d0-a92a-11ed-a31e-753411848f80",
        data
      )
      .then((returnedData) => {
        //console.log(returnedPerson);
        setName("");
        setEmail("");
        setMessage("");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Mensaje enviado.",
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Se ha producido un error. Contacte vía email o Linkedin.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="bg-[#f4f4f4] w-[95%] mx-auto py-28 montserrat grow-[2] flex justify-center items-center">
      <form onSubmit={handleSubmit} className="w-1/2">
        <div className={`${isXs || isSm ? "flex flex-col" : "form"}`}>
          {/* <div className="flex w-full justify-between "> */}
          <div className="flex flex-col c1r1">
            <label for="nombre" className="uppercase mb-2.5">
              Nombre
            </label>
            <input
              type="text"
              placeholder="Escriba su nombre"
              name="nombre"
              className="p-3 border-solid border-[1px] border-[#e4e4e4]"
              value={name}
              onChange={handleNameChange}
              required
            />
          </div>

          <div className="flex flex-col c2r1">
            <label for="email" className="uppercase mb-2.5">
              Email
            </label>
            <input
              type="text"
              placeholder="Escriba su email"
              name="email"
              className="p-3"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          {/* </div> */}

          <div className="flex flex-col c1r2">
            <label for="mensaje" className="uppercase mb-2.5">
              Mensaje
            </label>
            <textarea
              type="text"
              placeholder="Escriba su mensaje"
              name="mensaje"
              className="p-3 h-[200px]"
              value={message}
              onChange={handleMessageChange}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="uppercase bg-black hover:bg-[#43464d] text-white py-3 px-5 mt-6"
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Formulario;
