import useBreakpoints from "./useBreakPoints";

const Footer = () => {
  const { isXs, isSm, isMd } = useBreakpoints();
  return (
    <div>
      <ul className={`flex items-center h-20 ${isXs || isSm ? "justify-center" : "justify-end"}`}>
        <li className="mr-12 montserrat">
          <a
            href="https://www.linkedin.com/in/ballesterosrivasa/"
            className="uppercase text-[12px] text-[#8f8f91] hover:text-[#656567] tracking-[1px]"
            target="_blank"
            rel="noreferrer"
          >
            linkedin
          </a>
        </li>
        <li className={`${isXs || isSm ? "" : "mr-20"}`}>
          <p className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-mail mr-1.5"
              color="#8f8f91"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
            <spam className="text-[12px] text-[#8f8f91] tracking-[1px]">ballesterosrivasa@gmail.com</spam>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
