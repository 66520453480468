import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import htmlIcon from "../assets/html.png";
import cssIcon from "../assets/css.png";
import jsIcon from "../assets/js.png";
import nodeIcon from "../assets/node.png";
import reactIcon from "../assets/react.png";
import mongoDBIcon from "../assets/mongoDB.png";
import phpIcon from "../assets/php.png"
import laravelIcon from "../assets/laravelIcon.png"
import mysqlIcon from "../assets/mysqlIcon.png"
import useBreakpoints from "./useBreakPoints";

const Project = ({
  nombre,
  media,
  urlApp,
  img,
  mongoDB,
  linkDetalles,
  laravel,
}) => {
  const { isXs, isSm, isMd } = useBreakpoints();

  const languages = laravel ? (
    <>
      <li className="flex flex-col items-center justify-center">
        <img src={htmlIcon} alt="" />
        <span>HTML5</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={cssIcon} alt="" />
        <span>CSS3</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={jsIcon} alt="" />
        <span>Javascript</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={phpIcon} alt="" />
        <span>PHP</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={laravelIcon} alt="" className="w-[48px] h-[48px]" />
        <span>Laravel</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={mysqlIcon} alt="" className="w-[48px] h-[48px]" />
        <span>MySQL</span>
      </li>
    </>
  ) : (
    <>
      <li className="flex flex-col items-center justify-center">
        <img src={nodeIcon} alt="" />
        <span>Node.js</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={reactIcon} alt="" />
        <span>React</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={htmlIcon} alt="" />
        <span>HTML5</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={cssIcon} alt="" />
        <span>CSS3</span>
      </li>
      <li className="flex flex-col items-center justify-center">
        <img src={jsIcon} alt="" />
        <span>Javascript</span>
      </li>
      {mongoDB ? (
        <li className="flex flex-col items-center justify-center">
          <img src={mongoDBIcon} alt="" />
          <span>MongoDB</span>
        </li>
      ) : (
        ""
      )}
    </>
  );

  return (
    <div
      className={`${
        isXs || isSm ? "flex-col" : ""
      } flex justify-center mb-16 px-3.5`}
    >
      <Link to="/weatherApp">
        {img ? (
          <div className="relative">
            <img src={media} width="1080" className="blur-sm	" alt="" />
            <spam className="absolute bg-[#f4f4f4] p-4 uppercase top-[45%] left-[45%] shadow">
              próximamente
            </spam>
          </div>
        ) : (
          <video
            width="1080"
            src={media}
            loop
            autoplay="true"
            muted
            className="hover:opacity-80"
          />
        )}
        {/* <video width="1080" src={video} loop autoplay="true" muted /> */}
      </Link>
      <div
        className={`${
          isXs || isSm ? "w-full items-center mt-8" : "w-[25%] ml-8"
        } flex flex-col `}
      >
        <h1 className="mb-8 text-[1.5vw]">{nombre}</h1>
        {img ? (
          ""
        ) : (
          <>
            <a
              href={urlApp}
              className="uppercase bg-black hover:bg-[#43464d] text-white text-[1vw] py-3 px-5 w-fit text-center"
              target="_blank"
              rel="noreferrer"
            >
              Enlace a la aplicación
            </a>

            <Link to={linkDetalles}>
              <button className="uppercase bg-black hover:bg-[#43464d] text-white text-[1vw] py-3 px-5 mt-8 text-center">
                Detalles
              </button>
            </Link>
          </>
        )}

        {isXs || isSm ? (
          ""
        ) : (
          <ul className="flex mt-8 justify-around flex-wrap">
            {languages}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Project;
