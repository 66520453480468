import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import useBreakpoints from "./useBreakPoints";
import menuIcon from "../assets/menuIcon.png";
import { useEffect, useRef, useState } from "react";
import useIsMounted from "./useIsMounted";
import MenuButton from "./MenuButtons";

const Header = ({ currentLocation }) => {
  const { isXs, isSm, isMd } = useBreakpoints();
  const [display, setDisplay] = useState(false);
  const menuButtonRef = useRef();
  const isMounted = useIsMounted();

  useEffect(() => {
    const closeMenu = (event) => {
      if (event.target !== menuButtonRef.current) {
        if (display !== "default") {
          console.log("ENTRA");
          setDisplay(false);
        }
      }
    };

    document.body.addEventListener("click", closeMenu);

    return () => document.body.removeEventListener("click", closeMenu);
  }, []);

  const handleMenuIconClick = () => {
    // if (display === "default") {
    //   setDisplay(true);
    // } else {
      display === false || "cerrado" ? setDisplay("abierto"):setDisplay("cerrado");
    // setDisplay(!display);
    // console.log("HANDLEA");
    // }
  };

  // if (isXs || isSm || isMd) {
    return (
      <>
        <ul className="flex justify-between montserrat px-14 py-9">
          <Link to="/">
            <li className="font-medium border-b-[3px] border-black border-solid w-fit leading-[31px] text-3xl">
              Portfolio
            </li>
          </Link>
          {isXs || isSm || isMd ? <img
            ref={menuButtonRef}
            src={menuIcon}
            alt=""
            onClick={handleMenuIconClick}
          /> : <MenuButton currentLocation={currentLocation} />}
          
        </ul>
        <ul
          className={`flex flex-col uppercase text-sm	text-[#8f8f91] font-normal tracking-[1px] absolute bg-white w-full top-[100px] ${
            !display ? "menuHidden" : ""
          } ${display === "abierto" ? "menuAppear" : ""} ${display === "cerrado" ? "menuDisappear" : ""}`}
        >
          <li
            className={`hover:text-[#656567] mx-auto my-[20px] ${
              currentLocation === "Proyectos"
                ? "text-[#1a1b1f] font-semibold"
                : ""
            }`}
          >
            <Link to="/">Proyectos</Link>
          </li>

          <li
            className={`hover:text-[#656567] mx-auto my-[20px] ${
              currentLocation === "Sobre mí"
                ? "text-[#1a1b1f] font-semibold"
                : ""
            }`}
          >
            <Link to="/sobre">Sobre mí</Link>
          </li>

          <li
            className={`hover:text-[#656567] mx-auto my-[20px] ${
              currentLocation === "Contacto"
                ? "text-[#1a1b1f] font-semibold"
                : ""
            }`}
          >
            <Link to="/contacto">Contacto</Link>
          </li>
        </ul>
      </>
    );
  // }

  // return (
  //   <ul className="flex justify-between montserrat px-14 py-9">
  //     <Link to="/">
  //       <li className="font-medium border-b-[3px] border-black border-solid w-fit leading-[31px] text-3xl">
  //         Portfolio
  //       </li>
  //     </Link>
  //     <ul className="flex uppercase text-sm	text-[#8f8f91] font-normal tracking-[1px]">
  //       <Link to="/">
  //         <li
  //           className={`hover:text-[#656567] ${
  //             currentLocation === "Proyectos"
  //               ? "text-[#1a1b1f] font-semibold"
  //               : ""
  //           }`}
  //         >
  //           Proyectos
  //         </li>
  //       </Link>
  //       <Link to="/sobre">
  //         <li
  //           className={`mx-9 hover:text-[#656567] ${
  //             currentLocation === "Sobre mí"
  //               ? "text-[#1a1b1f] font-semibold"
  //               : ""
  //           }`}
  //         >
  //           Sobre mí
  //         </li>
  //       </Link>

  //       <Link to="/contacto">
  //         <li
  //           className={`hover:text-[#656567] ${
  //             currentLocation === "Contacto"
  //               ? "text-[#1a1b1f] font-semibold"
  //               : ""
  //           }`}
  //         >
  //           Contacto
  //         </li>
  //       </Link>
  //     </ul>
  //   </ul>
  // );
};

export default Header;
