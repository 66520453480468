import "./app.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import Contacto from "./components/Contacto";
import Weather from "./components/Weather";
import Food from "./components/Food";
import Sobre from "./components/Sobre";
import Social from "./components/Social";
import Jobs from "./components/Jobs";

function App() {
  return (
    <Router>
      <div className="App">

      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/weatherApp" element={<Weather />} />
        <Route path="/foodApp" element={<Food />} />
        <Route path="/socialApp" element={<Social />} />
        <Route path="/jobsApp" element={<Jobs />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/sobre" element={<Sobre />} />
      </Routes>
    </Router>
  );
}

export default App;
