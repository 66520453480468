import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const MenuButton = ({currentLocation}) => {
    return (
        <ul className="flex uppercase text-sm	text-[#8f8f91] font-normal tracking-[1px]">
          <Link to="/">
            <li
              className={`hover:text-[#656567] ${
                currentLocation === "Proyectos"
                  ? "text-[#1a1b1f] font-semibold"
                  : ""
              }`}
            >
              Proyectos
            </li>
          </Link>
          <Link to="/sobre">
            <li
              className={`mx-9 hover:text-[#656567] ${
                currentLocation === "Sobre mí"
                  ? "text-[#1a1b1f] font-semibold"
                  : ""
              }`}
            >
              Sobre mí
            </li>
          </Link>
  
          <Link to="/contacto">
            <li
              className={`hover:text-[#656567] ${
                currentLocation === "Contacto"
                  ? "text-[#1a1b1f] font-semibold"
                  : ""
              }`}
            >
              Contacto
            </li>
          </Link>
        </ul>
    )
}

export default MenuButton