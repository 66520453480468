import Header from "./Header";
import Footer from "./Footer";
import socialAppScreenshot1 from "../assets/social1.png"
import socialAppScreenshot2 from "../assets/social2.png"
import socialAppScreenshot3 from "../assets/social3.png"
import socialAppVideo from "../assets/socialApp.mp4"

const Social = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-1/2 my-8 mx-auto montserrat">
        <h1 className="text-[64px] leading-[80px] mb-11">
          Aplicación de red social
        </h1>

        <a
          href="https://socialapp.ballesterosrivasa.com/"
          className="uppercase bg-black hover:bg-[#43464d] text-white text-[1vw] py-3 px-5 w-fit text-center"
          target="_blank"
          rel="noreferrer"
        >
          Enlace a la aplicación
        </a>
        <h2 className="text-[20px] leading-[34px] mt-11 mb-3.5">
          Descripción del proyecto
        </h2>
        <p className="text-[#8f8f91] text-[16px] leading-[28px]">
          Es una aplicación responsiva con la que conectar con tu gente y compartir fotos. Para
          construirla he utilizado Node.js, React y Tailwind CSS para el
          frontend, Express para el backend, Axios para la conexión con la API y MongoDB como base de datos. 
          También he usado React Router para navegar entre las distintas rutas de la aplicación.
        </p>
      </div>

      <div className="flex flex-col w-full items-center mt-28">
        <img src={socialAppScreenshot1} alt="" className="mb-14" />
        <img src={socialAppScreenshot2} alt="" className="mb-14" />
        <img src={socialAppScreenshot3} alt="" className="mb-14" />
        <video
          // width="1080"
          src={socialAppVideo}
          loop
          autoplay="true"
          muted
          className="mb-14"
        />
      </div>
      <Footer />
    </>
  );
};

export default Social;
