import Header from "./Header";
import Footer from "./Footer";
import weatherAppVideo from "../assets/weatherApp.mp4";
import weatherAppScreenshot1 from "../assets/weatherApp1.png";
import weatherAppScreenshot2 from "../assets/weatherApp2.png";
import weatherAppScreenshot3 from "../assets/weatherApp3.png";

const Weather = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-1/2 my-8 mx-auto montserrat">
        <h1 className="text-[64px] leading-[80px] mb-11">
          Aplicación de meteorología
        </h1>

        <a
          href="http://weatherapp.ballesterosrivasa.com/"
          className="uppercase bg-black hover:bg-[#43464d] text-white text-[1vw] py-3 px-5 w-fit text-center"
          target="_blank"
          rel="noreferrer"
        >
          Enlace a la aplicación
        </a>
        <h2 className="text-[20px] leading-[34px] mt-11 mb-3.5">
          Descripción del proyecto
        </h2>
        <p className="text-[#8f8f91] text-[16px] leading-[28px]">
          Es una aplicación responsiva con la que consultar el estado
          meteorológico. Para construirla he utilizado Node.js, React y Tailwind
          CSS para el frontend, y Axios para la conexión con las API
          (https://restcountries.com/ y https://openweathermap.org/). Al acceder
          a la aplicación se muestran los datos de Motril, la ciudad donde vivo.
          Esto lo he hecho para que se vea cómo funciona rápidamente, por el
          mismo motivo también he añadido un botón para buscar los datos de una
          ciudad aleatoria. Con el menú que hay en la parte superior derecha de
          la aplicación se puede elegir el idioma y la unidad de medida de la
          temperatura.
        </p>
      </div>

      <div className="flex flex-col w-full items-center mt-28">
        <img src={weatherAppScreenshot1} alt="" className="mb-14"/>
        <img src={weatherAppScreenshot2} alt="" className="mb-14" />
        <img src={weatherAppScreenshot3} alt="" className="mb-14" />
        <video
          // width="1080"
          src={weatherAppVideo}
          loop
          autoplay="true"
          muted
          className="mb-14"
        />
      </div>
      <Footer />
    </>
  );
};

export default Weather;
