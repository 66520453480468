import weatherAppVideo from "../assets/weatherApp.mp4";
import foodAppVideo from "../assets/foodApp.mp4";
import socialAppVideo from "../assets/socialApp.mp4";
import jobsAppVideo from "../assets/jobsAppVideo.mp4"
import socialImg from "../assets/social.png";
import Project from "./Project";
import ecommerceImg from "../assets/ecommerce.png"
const Projects = () => {
  return (
    <div className="flex flex-col align-center justify-center w-full montserrat">
      <Project
        nombre="Portal de empleo"
        urlApp="https://jobsapp.ballesterosrivasa.com/"
        media={jobsAppVideo}
        linkDetalles="/jobsApp"
        laravel={true}
      />
      <Project
        nombre="Aplicación de red social"
        urlApp="https://socialapp.ballesterosrivasa.com/"
        media={socialAppVideo}
        linkDetalles="/socialApp"
        mongoDB={true}
        
      />
      <Project
        nombre="Aplicación de comida a domicilio"
        urlApp="http://foodapp.ballesterosrivasa.com/"
        media={foodAppVideo}
        linkDetalles="/foodApp"
      />
      <Project
        nombre="Aplicación de meteorología"
        urlApp="http://weatherapp.ballesterosrivasa.com/"
        media={weatherAppVideo}
        linkDetalles="/weatherApp"
      />
      <Project
        nombre="Aplicación de e-commerce"
        media={ecommerceImg}
        img={true}
        laravel={true}
      />
    </div>
  );
};

export default Projects;
